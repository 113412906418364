html {
  font-family: Nunito Sans;
}

body {
    background-color: rgb(252, 253, 255);
    font-family: Nunito Sans !important;
    color: #43464B;
    padding: 0;
    margin: 0;
}

.logo {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
  }

.__react_component_tooltip.type-error {
  background-color: #be4b49;
}

input:not(.otp-item) {
  border: 0;
  outline: 0;
  background: #fff;
  border-bottom: 1px solid #D5D5D5;
}

.otp-item {
  outline: 0;
  background: #fff;
  border: 1px solid #D5D5D5;
  margin: 10px;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}
